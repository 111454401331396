@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  direction: rtl;
  font-family: "Noto Sans Arabic", sans-serif;
}

#root {
  height: 100vh;
}
/* nav-bar */
.nav-bar {
  box-shadow: 0px 2px 10px 0px rgba(46, 111, 244, 0.2);
}
.mobile-menu {
  box-shadow: 0px -2px 10px 0px rgba(46, 111, 244, 0.2);
}

.box-shadow {
  box-shadow: 0px 4px 6px 0px rgba(37, 114, 230, 0.2);
}

/* mobil menu */
.calc-height {
  height: calc(100vh - 64px);
}
.calc-menu {
  top: calc(100% + 10px);
}
/* users */

th,
td {
  padding: 10px;
  width: fit-content;
}

tr:nth-child(even) {
  background-color: #eef4fa;
}
tr:not(:first-child):hover,
tr:not(:last-child):hover {
  background-color: #e0e6ee;
}

.med-outlet {
  height: calc(100vh - 128px);
}

/* NavLink */
a.active {
  background-color: #e0e6ee;
}

h2 {
  width: max-content;
}

@media (max-width: 900px) {
  .home-screen {
    width: 100vw !important;
  }
}
.back-icon {
  padding-left: 20px;
  background: url(./img/search-interface-symbol.png) no-repeat left;
  background-size: 20px;
  background-position-x: 15px;
  background-color: #eef4fa;
  transition: all;
}
.back-icon:focus {
  background-color: #e0e6ee;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.sign {
  background: linear-gradient(282deg, #1031d0 0%, #2e6ff4 50%, #89baf8 100%);
}

.container-form {
  width: 600px;
  border-radius: 25px;
  background: #eef4fa;
  box-shadow: 5px 5px 10px 0px rgba(17, 36, 123, 0.3);
  padding: 50px 30px;
  text-align: center;
  height: fit-content;
}
.input {
  color: #58647c;
  font-size: 20px;
  padding: 15px 20px;
  font-weight: 500;
  border-radius: 10px;

  border: 2px solid;
  background-color: transparent;
  transition: all 0.1s ease-in-out;
  outline: none;
}
.input:focus {
  border-color: #2e6ff4;
}

.blur-grid {
  background: (
    (linear-gradient(282deg, #1031d0 0%, #2e6ff4 50%, #89baf8 100%))
  );
}
.import {
  border-color: red !important;
}
